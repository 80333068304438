import { Content } from "@/ui";
import { createTitle } from "@/functions/createTitle";
import { mini_carousel_helper } from "@/helpers/mini_carousel_helper";
// import { logo_carousel_helper } from "@/helpers/logo_carousel_helper";
import { jobs_helper } from "@/helpers/jobs_helper";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} />
    </>
  );
}

export async function getStaticProps({}) {
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        {
          component: "Hero",
          props: {
            img: {
              path: "page.home.component.Hero.img",
            },
          },
        },
        {
          component: "MiniCarousel",
          props: {
            className: "my-4 my-md-5 text-white",
            title: { path: "page.home.component.MiniCarousel.title" },
            items: mini_carousel_helper.fetch({
              filter: (i) => i.tags.toLowerCase().includes("home") || i.tags.includes("*"),
            }),
          },
        },
        {
          component: "GridButtonsGrid",
          props: {
            className: "my-4 my-md-5",
            items: [
              {
                title: {
                  path: "page.home.component.GridButtonsGrid.temporary.title",
                  placeholder: "Temporary Jobs",
                },
                img: {
                  path: "page.home.component.GridButtonsGrid.temporary.img",
                },
                href: "/jobs/#/temporary",
              },
              {
                title: {
                  path: "page.home.component.GridButtonsGrid.permanent.title",
                  placeholder: "Permanent Jobs",
                },
                img: {
                  path: "page.home.component.GridButtonsGrid.permanent.img",
                },
                href: "/jobs/#/permanent",
              },
              {
                title: {
                  path: "page.home.component.GridButtonsGrid.nhs.title",
                  placeholder: "NHS Jobs",
                },
                img: {
                  path: "page.home.component.GridButtonsGrid.nhs.img",
                },
                href: "/jobs/?query=NHS",
              },
              {
                title: {
                  path: "page.home.component.GridButtonsGrid.international.title",
                  placeholder: "International Jobs",
                },
                img: {
                  path: "page.home.component.GridButtonsGrid.international.img",
                },
                href: "/jobs/#/international-jobs",
              },
            ],
          },
        },
        { component: "Divider" },
        {
          component: "LatestJobs",
          props: {
            items: jobs_helper.fetch(),
            visibleCount: 4,
            filters: true,
          },
        },
        // { component: "Divider" },
        // {
        //   component: "LogoCarousel",
        //   props: {
        //     title: { path: "page.home.component.LogoCarousel.title", placeholder: "" },
        //     items: logo_carousel_helper.fetch({
        //       filter: (i) => i.tags.toLowerCase().includes("home") || i.tags.includes("*"),
        //     }),
        //   },
        // },
      ],
    },
  };
}
